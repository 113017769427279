<template>
  <b-card>
      <b-form @submit.prevent>
        <b-row>
          <!-- studio adı-->
          <b-col cols="12">
              <b-form-group
                :label="$t('Facility Name')"
                label-for="company_name"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ItalicIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="company_name"
                    name="company_name"
                    type="text"
                    :placeholder="$t('Facility Name')"
                    v-model="studio_data.company_name"
                      @blur="$v.studio_data.company_name.$touch()"
                  />

                </b-input-group>
                <small v-if="!$v.studio_data.company_name.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>

          </b-col>

          <!-- studio resmi adı-->
          <b-col cols="12">

              <b-form-group
                :label="$t('Facility Official Name')"
                label-for="company_official_title"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CheckCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="company_official_title"
                    name="company_official_title"
                    type="text"
                    :placeholder="$t('Facility Name')"
                    v-model="studio_data.company_official_title"
                     @blur="$v.studio_data.company_official_title.$touch()"
                  />
                </b-input-group>
                <small v-if="!$v.studio_data.company_official_title.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>

          </b-col>
          <!-- Telefon Numarası-->
          <b-col cols="12">
            <b-form-group
              :label="$t('Phone Number')"
              label-for="phone-number"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="PhoneIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="phone-number"
                  name="phone-number"
                  :placeholder="$t('Phone Number')"
                  type="number"
                  maxlength="20"
                  v-model="studio_data.phone_number"
                  @blur="$v.studio_data.phone_number.$touch()"
                />
              </b-input-group>
                  <small v-if="!$v.studio_data.phone_number.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                  <small v-if="!$v.studio_data.phone_number.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                  <small v-if="!$v.studio_data.phone_number.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group :label="$t('City')" label-for="city">
              <v-select
                style="width: 100%; z-index: 450; display: inline-block"
                :reduce="x => x.city_id"
                v-model="selectedCity"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cities"
                value="city_id"
                label="city_name"
                name="city"
                @blur="$v.studio_data.city_id.$touch()"
                ></v-select>

                <small v-if="!$v.studio_data.city_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group :label="$t('Town')" label-for="town">
              <v-select
                style="width: 100%; z-index: 400; display: inline-block"
                :reduce="x => x.town_id"
                v-model="selectedTown"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="towns"
                value="town_id"
                label="town_name"
                name="town"
                @blur="$v.studio_data.town_id.$touch()"
              >
              </v-select>
                 <small v-if="!$v.studio_data.town_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
                <b-form-group label-for="scheduler" :label="$t('Company Type')">
                    <b-form-radio
                    v-model="studio_data.company_type"
                    name="company_type"
                    value="true"
                    class="d-inline custom-radio"
                    >
                    {{$t('Anonymous/Ltd')}}
                    </b-form-radio>
                    <b-form-radio
                    v-model="studio_data.company_type"
                    name="company_type"
                    value="false"
                    class="d-inline mx-2 custom-radio"
                    >
                    {{$t('Sole Proprietorship')}}
                    </b-form-radio>
                  <small v-if="!$v.studio_data.company_type.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                </b-form-group>
          </b-col> -->

        <!-- <b-col cols="12" v-if="studio_data.company_type == 'true' || studio_data.company_type == true">
            <b-form-group :label="$t('Tax Code')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="vi-first-name"
                  :placeholder="$t('Tax Code')"
                  type="number"
                  maxlength="20"
                  v-model="studio_data.tax_code"
                  @blur="$v.studio_data.tax_code.$touch()"
                />
              </b-input-group>
                 <small v-if="!$v.studio_data.tax_code.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="!$v.studio_data.tax_code.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                 <small v-if="!$v.studio_data.tax_code.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
        </b-col> -->
            <!-- <b-col cols="12" v-if="studio_data.company_type == 'false' || studio_data.company_type == false">
            <b-form-group :label="$t('Identification Number')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="vi-first-name"
                  :placeholder="$t('Identification Number')"
                  type="number"
                  maxlength="20"
                  v-model="studio_data.tax_code"
                  @blur="$v.studio_data.tax_code.$touch()"
                />
              </b-input-group>
                 <small v-if="!$v.studio_data.tax_code.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="!$v.studio_data.tax_code.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                 <small v-if="!$v.studio_data.tax_code.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col> -->
        <!--
          <b-col cols="12" v-if="studio_data.company_type == 'true' || studio_data.company_type == true">
            <b-form-group
              :label="$t('Tax Administration')"
              label-for="vi-task-administration"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="BookOpenIcon" />
                </b-input-group-prepend>
                <b-form-input
                name="vi-task-administration"
                  id="vi-task-administration"
                  :placeholder="$t('Tax Administration')"
                  v-model="studio_data.tax_administration"
                  @blur="$v.studio_data.tax_administration.$touch()"
                />
              </b-input-group>
                <small v-if="!$v.studio_data.tax_administration.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
          </b-col> -->

          <!-- <b-col cols="12">
            <b-form-group :label="$t('IBAN')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="vi-first-name"
                  :placeholder="$t('IBAN')"
                  maxlength="34"
                  v-model="studio_data.iban"
                  @blur="$v.studio_data.iban.$touch()"
                />
              </b-input-group>
                 <small v-if="!$v.studio_data.iban.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="!$v.studio_data.iban.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
            </b-form-group>
          </b-col> -->


          <b-col cols="12">
            <b-form-group
              :label="$t('Email')"
              label-for="email"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  name="email"
                  id="email"
                  :placeholder="$t('Email')"
                  v-model="studio_data.email"
                  @blur="$v.studio_data.email.$touch()"
                />
              </b-input-group>
                <small v-if="!$v.studio_data.email.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                <small v-if="!$v.studio_data.email.email" class="form-text text-danger" >{{$t("The email field must be a valid email")}}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group :label="$t('Adress')" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MapIcon" />
                </b-input-group-prepend>
                <b-form-textarea
                  v-model="studio_data.adress"
                  id="textarea-default"
                  :placeholder="$t('Adress')"
                  rows="1"
                  @blur="$v.studio_data.adress.$touch()"
                />
              </b-input-group>
                    <small v-if="!$v.studio_data.adress.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
          </b-col>

          <!-- reset and submit -->
          <b-col cols="12" class="right">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              type="reset"
              variant="outline-primary"
              class="mr-1"
              :to="{ name: 'studio_list' }"
            >
              {{ $t("Cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="update"
            >
              {{ $t("Update") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BCardText,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadio
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";

import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required, numeric, maxLength, email} from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
  },
  components: {
    ToastificationContent,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    Cleave,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardText,
    vSelect,
    mapGetters,
    mapActions,
    BFormRadio

  },
  props: {
  },
  data() {
    return {
      selectedCity:null,
      selectedTown:null,
      towns: [],
      cities: [],
      studio_data: {
        company_branch_id:null,
        company_name:null,
        company_official_title:null,
        phone_number:null,
        city_id :null,
        town_id :null,
        adress:null,
       // tax_administration:null,
       // tax_code:null,
       // iban: null,
        email: null,
        company_type: true
      },
      dataloaded:false,
      block: {
        blocks: [500],
        uppercase: false,
      },
      userData: {},
      isPerson: false,
    };
  },
  validations:
    {
        studio_data:{

                company_name:{
                  required:required
                },
                company_official_title:{
                  required:required
                },
                 phone_number:{
                    required:required,
                    numeric:numeric,
                    maxLength: maxLength(20)
                 },
                 city_id :{
                     required:required
                 },
                 town_id :{
                     required:required
                 },
                 adress:{
                   required:required
                 },
                email: {
                   email,
                   required
                },
                //  company_type: {
                //    required
                // }
        },

    },

  methods: {
    ...mapActions("mockUser", ["fetchUser"]),
    update() {

        this.$database.DistributorService.company_update(
        this.studio_data.company_branch_id,
        this.studio_data.company_name,
        this.studio_data.company_official_title, //datada  _official_title, title alanlarına aynı veriyi yazıyoruz
        this.studio_data.company_official_title,
        this.studio_data.phone_number,
        this.studio_data.city_id ,
        this.studio_data.town_id ,
      //  this.studio_data.tax_administration,
      //  this.studio_data.tax_code,
        this.studio_data.adress,
       // this.studio_data.iban,
        this.studio_data.email,
      //  this.studio_data.company_type
      ).then((res) => {
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Action failed!'),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Facility Edited Succesful"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push('/studioList')
        }
      });
    },

    async getData()
    {
        await   this.$database.DistributorService.get_studio_by_id(this.studio_id).then(
        res => {
          if (!res.is_success) return;
                this.getCities()
                 this.studio_data = res.result;
                 this.selectedCity=res.result.city_id
                 this.selectedTown=res.result.town_id
        }
      );
      this.dataloaded=true
    },


   async getCities()
    {
        await   this.$database.DistributorService.studio_get_cities().then((res) => {
        if (!res.is_success) return;
        this.cities = res.result;

        this.getTowns();
      });
    },

    getTowns() {

      this.$database.DistributorService.studio_get_towns_by_city_id(
        this.studio_data.city_id
      ).then((res) => {
        if (!res.is_success) return;
        this.towns = res.result;
      });
    },

  },
  watch: {

   //2.kontrol mekanizmam - asenkron inputları izleyerek şu an 20 (max) karakter ile sınırlandırıyorum
    'studio_data.phone_number'(val){
      if(this.studio_data.phone_number.length > 20){
        this.studio_data.phone_number = val.slice(0,20)
      }
    },
    'studio_data.tax_code'(val){
      if(this.studio_data.tax_code.length > 20){
        this.studio_data.tax_code = val.slice(0,20)
      }
    },
    'studio_data.company_type'(val){
      if(val == 'false' || val == false){
        this.studio_data.tax_administration = 'Şahıs Şirketi'
      }else {
        this.studio_data.tax_administration = ''
      }
    },

    selectedCity(val)
     {
        if(this.dataloaded===true)
        {
            this.selectedTown =null;
            this.studio_data.city_id = val;
            this.towns = [];
            this.$database.DistributorService.studio_get_towns_by_city_id(
              val
            ).then((res) => {
              if (!res.is_success)
                  return;
              this.towns = res.result;
                  this.selectedTown=res.result[0].town_id
            });
        }
    },
    selectedTown()
     {
             this.studio_data.town_id = this.selectedTown
     }
  },
  created() {
    this.studio_id = this.$route.params.studioID;
    this.getData();

  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/studioEdit.scss" scoped></style>
