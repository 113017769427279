import { render, staticRenderFns } from "./studio_edit.vue?vue&type=template&id=48db3903&scoped=true&"
import script from "./studio_edit.vue?vue&type=script&lang=js&"
export * from "./studio_edit.vue?vue&type=script&lang=js&"
import style0 from "@/styles/scss/studioEdit.scss?vue&type=style&index=0&id=48db3903&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48db3903",
  null
  
)

export default component.exports